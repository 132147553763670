import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import constants from '../constants'

const PrivateRoute = ({ component: Component, ...rest }) => {
  return (
    <Route {...rest} render={(props) => (sessionStorage.getItem(constants.GC_TOKEN)? <Component {...props} />: <Redirect to='/login' /> )} />
  );
}

export default PrivateRoute;
