import React, { useReducer } from 'react'
import { BrowserRouter as Router, Switch } from 'react-router-dom'
import { createBrowserHistory } from 'history'
import PublicRoute from './PublicRoute'
import PrivateRoute from './PrivateRoute'
import AdminRoute from './AdminRoute'
import Home from '../components/home/Home'
import Management from '../components/management/Management'
import UserCreate from '../components/management/users/UserCreate/UserCreate'
import UserUpdate from '../components/management/users/UserUpdate/UserUpdate'
import ConversationDetails from '../components/conversationDetails/ConversationDetails'
import UsersManagement from '../components/management/users/UsersManagement/UsersManagement'
import ConversationAttributes from '../components/conversationAttributes/conversationAttributes'
import AccessReport from '../components/audits/accessReport/AccessReport'
import Main from '../components/Main'
import Unauthorized from '../components/unauthorized/Unauthorized'
import SessionReducer from '../components/reducers/session'
import SessionContext from '../components/context/session'
import PrivacyDisclaimer from '../components/privacyDisclaimer/PrivacyDisclaimer'
import Download from '../components/download/Download'
import PureCloudLogin from "../components/login/purecloud/PureCloudLogin";
const history = createBrowserHistory()
const AppRouter = () => {
    const [session, dispatch] = useReducer(SessionReducer, [])
//<PublicRoute path="/disclaimer" exact={true} component={PrivacyDisclaimer} history={history} />
    return (
        <SessionContext.Provider value={{ session, dispatch }}>
            <Router history={history}>
                <Switch>
                    <PublicRoute path="/" exact={true} component={Main} history={history} />
                    <PublicRoute path="/login" exact={true} component={PureCloudLogin} />
                    <PublicRoute path="/disclaimer" exact={true} component={PrivacyDisclaimer} history={history} />
                    <PublicRoute path="/unauthorized" component={Unauthorized} />
                    <PrivateRoute path="/conversations" exact={true} component={Home} history={history} />
                    <PrivateRoute path="/conversations/:id/details" exact={true} component={ConversationDetails} history={history} />
                    <AdminRoute path="/management/searchview" exact={true} component={ConversationAttributes} history={history} />
                    <AdminRoute path="/management" exact={true} component={Management} history={history} />
                    <AdminRoute path="/management/users/" exact={true} component={UsersManagement} history={history} />
                    <AdminRoute path="/management/users/create" exact={true} component={UserCreate} history={history} />
                    <AdminRoute path="/management/users/update/:id" exact={true} component={UserUpdate} history={history} />
                    <AdminRoute path="/audits/access/" exact={true} component={AccessReport} history={history} />
                    <PrivateRoute path="/downloads/" exact={true} component={Download} history={history} />
                </Switch>
            </Router>
        </SessionContext.Provider>
    )
}

export default AppRouter