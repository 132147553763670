import React, { useEffect, Fragment } from 'react'
import { useHistory } from 'react-router-dom'
import { getParameterByName , getUrlParameter} from "../services/utils";
import config from '../config'
import { pureCloudService } from "../services/purecloud";
import constants from '../constants'
import backendAPI from '../services/backend'



const Main = () => {
    const history = useHistory()

    const fetchDisclaimerContent = async () => {
        try {
            const disclaimerResponse = await backendAPI.getDisclaimerContent(tempToken);
            console.log(`PrivacyDisclaimer ${JSON.stringify(disclaimerResponse)}`);
            setConsentResponse(disclaimerResponse.consentSettings);
            sessionStorage.setItem(constants.SESSION_KEY_DISCLAIMER_RESPONSE, JSON.stringify(disclaimerResponse.consentSettings))
        } catch (error) {
            setError(`An error occured while fetching data:${JSON.stringify(error.message)}`)
            console.log(error)
        } finally {
            setIsWaitingModalOpen(false)
        }

    }

    const notifyUserLogin = async (tempToken) => {
        try {
            console.log("Calling notify user login")
            sessionStorage.setItem(constants.GC_TOKEN, tempToken);
            const notifyLoginResponse = await backendAPI.postUserLogin(tempToken);
            sessionStorage.setItem(constants.SESSION_KEY_USER_LOGIN_RESPONSE, JSON.stringify(notifyLoginResponse))
            sessionStorage.setItem(constants.SESSION_KEY_CONSENTAGREED, true);
            console.log(`notifyUserLogin ::: Notify user login response ${JSON.stringify(notifyLoginResponse)}`);
            //setLoginResponse(notifyLoginResponse)
            console.log('Navigating to Conversation Page')
            history.push("/conversations");
            
            //history.push("/management/users/");
            
        } catch (error) {
            //setError(`An error occured while fetching data:${JSON.stringify(error.message)}`)
            console.log(error)
        } finally {
            //setSendingLogin(false)
        }

    }

    useEffect(() => {
        const init = async () => {
            console.log('main.useEffect.init')
            var hash = window.location.hash.substring(1);
            var params = {}
            hash.split('&').map(hk => { 
            let temp = hk.split('='); 
                params[temp[0]] = temp[1] 
            });
            console.log(params); //Here are the params to use
            const queryString = window.location.hash
            console.log(queryString);
            console.log(window.location);
            
            const urlParams = new URLSearchParams(queryString);
            console.log(`urlParams are ${urlParams}`);
            console.log(`window.locations are ${window.location}`);
            console.log(`URL Params are ${getUrlParameter(constants.GC_ACCESS_TOKEN)}`);
            console.log(params.access_token);
            const token = params.access_token;
            const error = params.error;
            console.log(error);
            let environment = new URLSearchParams(window.location.search).get("environment");
            let sessionEnvironment = sessionStorage.getItem(constants.GC_ENVIRONMENT);
            console.log(`Before Check :: Environment is ${environment}`)
            console.log(`Before Check :: sessionEnvironment is ${sessionEnvironment}`)
            if(sessionEnvironment){
                // do nothing
                environment = sessionEnvironment;
            }else{
                if(environment){
                    sessionStorage.setItem(constants.GC_ENVIRONMENT,environment);
                }else{
                    console.log(`Environment not Found hence resetting it to default environment`);
                    sessionStorage.setItem(constants.GC_ENVIRONMENT,config.defaultEnvironment);
                }
            }
            console.log(`Environment is ${environment}`);

            console.log(`token: ${token}`);
            if (token){
                console.log(`moving to Disclaimer Page`);
                sessionStorage.setItem(constants.GC_TOKEN,getParameterByName(constants.GC_ACCESS_TOKEN));
                //fetchDisclaimerContent();
                notifyUserLogin(token);
                //history.push({ pathname: '/disclaimer', state: { token: token } })
                
            }else{
                console.log('Main.js:::Token is empty , Trying to Login.');
                history.push('/login');
            }
            
            console.log(getParameterByName(constants.GC_ACCESS_TOKEN));

            if (getParameterByName(constants.GC_ACCESS_TOKEN)) {
                sessionStorage.setItem(constants.GC_TOKEN,getParameterByName(constants.GC_ACCESS_TOKEN));
            }
           if (sessionStorage.getItem(constants.GC_TOKEN)) {
                try {
                    console.log("Initialize");
                    // const organization = await getOrganization(sessionStorage.getItem(constants.GC_ENVIRONMENT), sessionStorage.getItem(constants.GC_TOKEN))
                    // await validateSubscription(organization.id, 'pureclouddatatables')
                    console.log(`Environment now is ${sessionStorage.getItem(constants.GC_ENVIRONMENT)}`);
                    const loggedInUser = await pureCloudService.getCurrentUserDetails(
                        sessionStorage.getItem(constants.GC_ENVIRONMENT),
                        sessionStorage.getItem(constants.GC_TOKEN)
                    );
                    console.log(`Current logged in user is : ${JSON.stringify(loggedInUser)}`);
                    const divisionId = loggedInUser.division.id;
                    console.log("User division ID", divisionId);
                    sessionStorage.setItem(constants.GC_DIVISION_ID, divisionId);

                    const roles = await pureCloudService.getRoles(
                        sessionStorage.getItem(constants.GC_ENVIRONMENT),
                        sessionStorage.getItem(constants.GC_TOKEN),
                        loggedInUser.id
                    );
                    console.log("roles", roles);
                const isUser = roles.roles.find((r) => r.name === config.userRole)? true: false;

                console.log("isUser", isUser);

                if (isUser) {
                    history.push("/home");
                } else {
                    history.push("/unauthorized", "Unauthorized Genesys Cloud user");
                }
            }catch(error){
                console.log("Error : " + error);
                if (error.status === 401) {
                    //history.push("/login");
                }else{
                    history.push("/unauthorized", error.message);
                } 
            }
        }else{
               // history.push("/login");
        }};
        init()
    }, [history])

    return (<Fragment></Fragment>)
}

export default Main